<template>
    <main class="o-main o-main--archive" id="main">
        <h1 class="c-heading c-heading--hashtag c-txt c-txt--center c-txt--upper u-color--light-blue" v-if="title">{{ title }}</h1>
        <div class="o-container o-container--full o-layout o-layout--multiline o-layout--3">
            <router-link v-for="(p, j) in archive" :to="`/post/${p.post_name}`" class="o-layout__item c-tease c-tease--grid" :key="j">
                <slot :post="p"></slot>
                <span class="c-tease__img"
                      :style="{ backgroundImage: `url(${ p.featured_image ? p.featured_image.sizes.large.src[0] : '' })` }"></span>
                <span class="c-tease__subtitle">{{ p.post_tagline }}</span>
                <h2 class="c-tease__title">{{ p.post_title }}</h2>
            </router-link>
        </div>
    </main>
</template>

<script>
    import api from '@/api'

    export default {
        name: 'Archive',
        data () {
          return {
              title: '',
              archive: []
          }
        },

        beforeMount() {
            api.getArchiveName(this.$route.params.archiveSlug, tagName => {
                this.title = tagName
            })

            api.getArchive(this.$route.params.archiveSlug, archive => {
                this.archive = archive
            })
        }
    }
</script>

<style lang="scss" src="@/styles/components/_heading.scss"></style>
<style lang="scss" src="@/styles/components/_tease.scss"></style>